<template>
    <div>
        <el-dialog
            title="付款信息"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-form ref="form" :model="form" label-position="left" label-width="120px">
                    <el-form-item label="付款户名">
                        <span>{{ form.accountName || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="付款卡号">
                        <span>{{ form.accountNo || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="汇款金额">
                        <span>{{ form.payAmount?(form.payAmount/100).toFixed(2):'0.00' }}</span>
                    </el-form-item>
                    <el-form-item label="收款户名">
                        <span>{{ form.destinationAccountName || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="收款卡号">
                        <span>{{ form.destinationAccountNumber || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="开户银行">
                        <span>{{ form.destinationAccountBank || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="省市信息">
                        <span>{{ form.city || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="备注信息">
                        <span>{{ form.remark || '-' }}</span>
                    </el-form-item>
                    <el-form-item label="汇款截止日期">
                        <span>{{ form.deadline || '-' }}</span>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button type="primary"  @click="submit">关 闭</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                form: {
                    accountName: '',
                    accountNo: '',
                    payAmount: '',
                    destinationAccountName: '',
                    destinationAccountNumber: '',
                    destinationAccountBank: '',
                    city: '',
                    remark: '',
                    deadline: '',
                },
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form']){
                    // this.$refs['form'].resetFields()
                }
            },
        },
        created () {
            // this.$getDic('purchasePayType').then(res=>{ this.stateDic = res; })
        },
        methods:{
            submit () {
                this.dialogVisible = false
            },
            windowOpen (url) {
                window.open(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
    .tips{
        font-size: 12px;
        color: #E6A23C;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>