<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">加盟商进件管理</span>
            <div class="a-flex-rcc">
                <!-- <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button> -->
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="companyUpgrade()">二级商户进件</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-company-solo-select label="商户" placeholder="请选择（可输入搜索）" v-model="pageParam.params.companyId"></le-company-solo-select>
                <le-select-local-search label="申请状态" v-model="pageParam.params.applyState" :options="applyStateDic" />
                <le-input class="searchForm-label" label="业务申请编号" v-model="pageParam.params.requestNo" />
            </le-search-form>
            <le-pagview ref="financePackage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.financePackageList">
                <el-table ref="financePackageList" :data="tableData" :highlight-current-row="true" @filter-change='filterFun' v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column label="商户名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="进件类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.servicePlatform | initDic(servicePlatformDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="业务申请编号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.outRequestNo || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="微信支付申请单号" min-width="130">
                        <template slot-scope="{ row }">
                            <span>{{ row.applymentId || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请状态" min-width="120">
                        <template slot-scope="{ row }">
                            <span 
                                class="a-c-blue font-point" 
                                :class="row.applyState == 'REJECTED'?'a-c-error':'a-c-blue'" 
                                @click="showPaymentInfo(row)">{{ row.applyState | initDic(applyStateDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="签约状态" min-width="120">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-rfsc">
                                <span>签约状态</span>
                                <el-tooltip class="item" effect="dark" content="" placement="top">
                                    <div slot="content" class="a-fle-cfsfs">
                                        <div>1. UNSIGNED：未签约。</div>
                                        <div class="a-plr-15">该状态下，电商平台可查询获取签约链接，引导二级商户的超级管理员完成签约。</div>
                                        <div>2. SIGNED ：已签约。</div>
                                        <div class="a-plr-15">指二级商户的超级管理员已完成签约。注意：若申请单被驳回，商户修改了商户主体名称、法人名称、<br />超级管理员信息、主体类型等信息，则需重新签约。</div>
                                        <div>3. NOT_SIGNABLE：不可签约。</div>
                                        <div class="a-plr-15">该状态下，暂不支持超级管理员签约。一般为申请单处于已驳回、已冻结、机器校验中状态，无法签约。</div>
                                    </div>
                                    <span class="el-icon-question"></span>
                                </el-tooltip>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>{{ row.signState | initDic(signStateDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="法人验证链接" min-width="120">
                        <template slot-scope="scope">
                            <qrcode v-if="scope.row.legalValidationUrl" :qrVal="scope.row.legalValidationUrl" :id="'legalValidationUrl'+scope.$index">
                                <span>请使用申请时所填写的法人实名认证的微信扫码</span>
                            </qrcode>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="签约验证链接" min-width="120">
                        <template slot-scope="scope">
                            <qrcode v-if="scope.row.signUrl" :qrVal="scope.row.signUrl" :id="'signUrl'+scope.$index">
                                <span>请使用申请时所填写的超级管理员实名认证的微信扫码</span>
                            </qrcode>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="电商平台二级商户号" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.subMchid || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="驳回原因详情" min-width="120">
                        <template slot-scope="{ row }">
                            <el-tooltip placement="top">
                                <div slot="content" style="width:400px">{{ row.auditDetail || '-' }}</div>
                                <span class="textOver2">{{ row.auditDetail || '-' }}</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="即时刷新" placement="top">
                                <img src="../../assets/icon/option-history.png" class="a-wh-16" @click="refreshStatus(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
								<img src="../../assets/icon/option-detail.png" class="a-wh-16 a-ml-12" @click="companyUpgrade(scope.row, 'info')" />
							</el-tooltip>
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
								<!-- <img src="../../assets/icon/option-edit.png" v-if="scope.row.applyState == 'REJECTED'" class="a-wh-16 a-ml-12" @click="companyUpgrade(scope.row, 'edit')" /> -->
								<img src="../../assets/icon/option-edit.png" class="a-wh-16 a-ml-12" @click="companyUpgrade(scope.row, 'edit')" />
							</el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <payment-info ref="paymentInfo"></payment-info>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    import paymentInfo from './child/paymentInfo.vue'
    import Qrcode from './child/qrcode.vue'
    export default {
        components: { paymentInfo, Qrcode },
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: null,
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 92 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }
                    },
                },
                applyStateDic: [],
                signStateDic: [],
                servicePlatformDic: [{
                    label: '微信进件',
                    value: 1
                },{
                    label: '宝付进件',
                    value: 2
                }],//进件类型字典
            }
        },
        created () {
            this.$getDic('applyment_state','select').then(res=>{ this.applyStateDic = res; })
            this.$getDic('sign_state','select').then(res=>{ this.signStateDic = res; })
            this.pageParam = {
                url: this.$Config.apiUrl.merchantPage,
                method: "post",
                params: {
                    applyState: '',
                    companyId: '',
                    requestNo: ''
                },
                freshCtrl: 1,
            }
        },
        mounted () {
            
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    applyState: '',
                    companyId: '',
                    requestNo: ''
                };
                this.$refs['financePackageList'].clearFilter()
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['financePackage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='status'){
                        if(value[key].length==0){
                            this.pageParam.params.status = ''
                        }else{
                            this.pageParam.params.status = value[key][0]
                        }
                    }
                }
                this.$refs['financePackage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            companyUpgrade (datas, type) {
                this.$router.push({
                    path: '/companyUpgrade/companyUpgrade-edit',
                    query: {
                        id: datas?datas.id:'',
                        editType: type?type:'add'
                    }
                })
            },
            refreshStatus (datas) {
                this.$Axios._get({
                    url: this.$Config.apiUrl.merchantApplyState,
                    method: "get",
                    params: {
                        id: datas.id
                    }
                }).then(res => {
                    this.pageParam.freshCtrl++;
                })
            },
            showPaymentInfo (datas) {
                if(datas.applyState == 'ACCOUNT_NEED_VERIFY'){
                    this.$refs['paymentInfo'].form = {
                        accountName: datas.accountName,
                        accountNo: datas.accountNo,
                        payAmount: datas.payAmount,
                        destinationAccountName: datas.destinationAccountName,
                        destinationAccountNumber: datas.destinationAccountNumber,
                        destinationAccountBank: datas.destinationAccountBank,
                        city: datas.city,
                        remark: datas.remark,
                        deadline: datas.deadline,
                    }
                    this.$refs['paymentInfo'].dialogVisible = true
                }
            },
            exportfile () {
                let startDate = this.pageParam.params.startDate || this.$getDay.getTodayBeforeDays(92) + ' 00:00:00'
                let endDate = this.pageParam.params.endDate || this.$getDay.getTodayBeforeDays(0) + ' 00:00:00'
                this.$exfile({
                    code: 4,
                    fileName: startDate.split(' ')[0] + ' 至 ' + endDate.split(' ')[0] + ' 套餐账单',
                    startTime: startDate.split(' ')[0],
                    endTime: endDate.split(' ')[0],
                    params: {
                        ...this.pageParam.params,
                        startDate: startDate,
                        endDate: endDate
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    /deep/ .searchForm-label{
        .s-search-label{
            width: 100px !important;
        }
    }
    .s-btn-add{
        width: 135px;
    }
</style>